import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const Seo = lazy(() => import('sections/hero/Seo.js'));
const BotonAPP = lazy(() => import('sections/services/BotonAPP.js'));
const PlanSeo = lazy(() => import('sections/about/PlanSeo.js'));
const SEOPrecios = lazy(() => import('sections/services/SEOPrecios.js'));
const BotonSeo = lazy(() => import('sections/hero/BotonSeo.js'));
const Hola = lazy(() => import('sections/hero/Hola.js'));
const BoxCol2Seo = lazy(() => import('sections/hero/BoxCol2Seo.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));


class Index extends React.Component {

  render() {

    

    return (
      <div>
        <Helmet>
          <title>Agencia SEO en Colombia: Posicionamiento web confiable</title>
          <meta name="description" content="Descubre nuestra agencia SEO en Santa Rosa de Cabal Colombia para un posicionamiento web efectivo. Expertos en estrategias de SEO adaptadas a tus necesidades." />
        </Helmet>
        <Layout>
          <Seo />          
          <PlanSeo />
          <SEOPrecios />
          <BotonSeo />
          <Hola />
          <BoxCol2Seo />          
          <Llamenos />
          <BotonAPP />          
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`